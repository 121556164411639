import { ReactNode, useEffect } from 'react';
import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { ImageWithConfigFragment } from '@codegen/cmsUtils';
import * as Dialog from '@radix-ui/react-dialog';
import { legacyZIndices } from '@ui-v2/theme/zIndices';
import { ThemePaddingProps } from '@ui-v2/types/props';
import { useBaseAnalytics } from '@ui-v2/utils/contexts/BaseAnalyticsContext';
import { hexToRGBA, mqMax, mqMin } from '@ui-v2/utils/styleUtils';
import Box from '../Box/Box';
import Button from '../Button/Button';
import Text from '../Text/Text';

export type ModalProps = {
  autoFocus?: boolean;
  children?: React.ReactNode;
  closeIcon?: ImageWithConfigFragment | null;
  footer?: ReactNode;
  header?: ReactNode;
  id: string;
  isOpen: boolean;
  mobileSize?: 'full-screen' | 'drawer';
  onOpenChange?: (isOpen: boolean) => void;
  title?: string;
} & ThemePaddingProps;

const MODAL_DISPLAY_BREAKPOINT = 768;

const overlayShow = keyframes`
    from { opacity: 0; }
    to { opacity: 1; }
`;

const contentShow = keyframes`
from {
  opacity: 0;
  transform: translate(-50%, -48%) scale(0.96);
}
to {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
}`;

const contentShowMobile = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`;

const StyledOverlay = styled(Dialog.Overlay)(
  ({ theme }) => css`
    position: fixed;
    z-index: ${theme.zIndices.modal - 1};
    animation: ${overlayShow} 400ms cubic-bezier(0.16, 1, 0.3, 1);
    background: ${hexToRGBA(theme.colours.surface.inverse, 0.6)};
    inset: 0;
  `,
);

const StyledTitle = styled(Dialog.Title)(
  () => css`
    position: sticky;
    z-index: 2;
    top: 0;
  `,
);

const StyledContent = styled(Dialog.Content, {
  shouldForwardProp: (prop) => prop !== 'mobileSize',
})<{
  mobileSize: ModalProps['mobileSize'];
}>(({ mobileSize, theme }) => [
  css`
    position: fixed;
    z-index: ${legacyZIndices.max + 1};
    bottom: 0;
    left: 0;
    display: flex;
    width: 100vw;
    height: 'auto';
    max-height: 100vh;
    max-height: 100dvh;
    flex-direction: column;
    border-radius: ${theme.shape.borderRadiusS}px;
    animation: ${contentShowMobile} 300ms ease;
    background-color: ${theme.colours.surface.main};
    box-shadow: ${theme.shadows.medium};
    overflow-y: auto;
    transform: translateY(0);

    ${mqMin[MODAL_DISPLAY_BREAKPOINT]} {
      z-index: ${legacyZIndices.max};
      top: 50%;
      bottom: initial;
      left: 50%;
      width: 90vw;
      max-width: 944px;
      height: auto;
      max-height: 85vh;
      animation: ${contentShow} 200ms cubic-bezier(0.16, 1, 0.3, 1);
      transform: translate(-50%, -50%);
    }
  `,
  mobileSize === 'full-screen' &&
    css`
      ${mqMax[MODAL_DISPLAY_BREAKPOINT]} {
        height: 100vh;
        animation: none;
      }
    `,
]);

const Modal = ({
  autoFocus = true,
  children,
  closeIcon,
  footer,
  header,
  id,
  isOpen,
  mobileSize = 'drawer',
  onOpenChange,
  title,
  ...props
}: ModalProps) => {
  const { sendModalShownEvent } = useBaseAnalytics();
  useEffect(() => {
    if (isOpen) {
      sendModalShownEvent({ id });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const shouldShowTitleBox = title || closeIcon || header;

  return (
    <Dialog.Root onOpenChange={onOpenChange} open={isOpen}>
      <Dialog.Portal>
        <StyledOverlay />
        <StyledContent
          mobileSize={mobileSize}
          onOpenAutoFocus={(e) => {
            if (!autoFocus) {
              e.preventDefault();
            }
          }}
        >
          {shouldShowTitleBox && (
            <StyledTitle>
              <Box
                alignItems="center"
                bg="surface.main"
                borderBottom="subdued"
                display="flex"
                justifyContent="space-between"
                p={16}
              >
                {title && (
                  <>
                    {/* TODO: Fix around while we don't have repsonsive font sizes */}
                    <Box display={['none', 'none', 'block']}>
                      <Text variant="heading-4">{title}</Text>
                    </Box>
                    <Box display={['block', 'block', 'none']}>
                      <Text variant="heading-5">{title}</Text>
                    </Box>
                  </>
                )}
                {header && header}
                {closeIcon && (
                  <Dialog.Close asChild>
                    <Button
                      aria-label="Close"
                      icon={closeIcon}
                      size="iconRegular"
                      variant="tertiary"
                    />
                  </Dialog.Close>
                )}
              </Box>
            </StyledTitle>
          )}
          <Box flexGrow={1} p={16} {...props}>
            {children}
          </Box>
          {footer && (
            <Box
              bg="surface.main"
              borderTop="subdued"
              bottom={0}
              p={16}
              position="sticky"
              width="100%"
            >
              {footer}
            </Box>
          )}
        </StyledContent>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default Modal;
