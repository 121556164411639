import { css } from '@emotion/react';
import {
  SpecificTypographyFragment,
  TypographyStyleFragment,
} from '@codegen/cmsUtils';
import { Breakpoint, breakpoints } from '@ui-v2/theme/layout';
import { hexToCSSFilter } from 'hex-to-css-filter';

export const mqPercy = '@media only percy';

export const hideFromPercy = css`
  ${mqPercy} {
    visibility: hidden;
  }
`;

export const isHex = (color: string) =>
  new RegExp(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/).test(color);

/**
 * Evaluates the color to a css filter if it satifies the
 * constraints of a hex value.
 */
export const createCSSFilterFromHex = (color: string) =>
  isHex(color)
    ? css`
        filter: ${hexToCSSFilter(color).filter};
        ${hideFromPercy}
      `
    : '';

export const setCursorOnHover = css`
  &:hover {
    cursor: pointer;
  }
`;

export const hexToRGBA = (h: string, opacity: number) => {
  let hex = h.startsWith('#') ? h.slice(1) : h;

  if (hex.length === 3) {
    hex = Array.from(hex).reduce((str, x) => str + x + x, '');
  }
  const values = hex
    .split(/([a-z0-9]{2,2})/)
    .filter(Boolean)
    .map((x) => parseInt(x, 16));

  return `rgba${values.length === 4 ? 'a' : ''}(${values.join(
    ', ',
  )}, ${opacity})`;
};

export const resetButton = css`
  padding: 0;
  border: none;
  margin: 0;
  appearance: none;
  background: transparent;
  line-height: inherit;
`;

export const createLegacyTypography = (
  typography: SpecificTypographyFragment,
) => css`
  font-family: ${typography.fontFamily};
  font-size: ${typography.fontSize};
  font-weight: ${typography.fontWeight};
  letter-spacing: ${typography.letterSpacing};
  line-height: ${typography.lineHeight};
  text-decoration: ${typography.textDecoration};
  text-decoration-color: ${typography.textDecorationColor};
  text-transform: ${typography.textTransform};
`;

export const mqMin = breakpoints.reduce(
  (mq, breakpoint) => ({
    ...mq,
    [breakpoint]: `@media (min-width: ${breakpoint}px)`,
  }),
  {} as Record<Breakpoint, string>,
);

export const mqMax = breakpoints.reduce(
  (mq, breakpoint) => ({
    ...mq,
    [breakpoint]: `@media (max-width: ${breakpoint}px)`,
  }),
  {} as Record<Breakpoint, string>,
);

export const createTypographyObjectNotation = (
  typography: TypographyStyleFragment,
) => ({
  fontFamily: `${typography.fontFamily}`,
  fontSize: typography.fontSize,
  fontWeight: typography.fontWeight,
  letterSpacing: typography.letterSpacing,
  lineHeight: `${typography.lineHeightPercent}%` || 'initial',
  textTransform: typography.textTransform,
});
