import useTranslation from 'next-translate/useTranslation';
import { ContentFragment, ImageWithConfigFragment } from '@codegen/cmsUtils';
import { Language } from '@shared/types/enums';
import Modal from '@ui-v2/core/Modal/Modal';
import OneButtonModalFooter from '@ui-v2/core/Modal/OneButtonModalFooter';
import DefaultErrorBoundary from '../ErrorBoundary/DefaultErrorBoundary';
import LoadingShimmer from '../LoadingShimmer/LoadingShimmer';
import StandaloneContent from './StandaloneContent';

interface Props {
  className?: string;
  closeIcon: ImageWithConfigFragment | null;
  content?: ContentFragment[];
  height?: string;
  id: string;
  isOpen: boolean;
  locale: Language;
  residency: string;
  title?: string;
  toggleModal: () => void;
}

const StandaloneContentModal = ({
  content,
  id,
  isOpen,
  locale,
  residency,
  title,
  toggleModal,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Modal
      footer={
        <OneButtonModalFooter onOpenChange={toggleModal} text={t('Got it')} />
      }
      id={id}
      isOpen={isOpen}
      onOpenChange={toggleModal}
      title={title}
    >
      <DefaultErrorBoundary
        text={t('Something went wrong when fetching this content')}
      >
        {content ? (
          <StandaloneContent
            content={content}
            locale={locale}
            residency={residency}
          />
        ) : (
          <LoadingShimmer />
        )}
      </DefaultErrorBoundary>
    </Modal>
  );
};

export default StandaloneContentModal;
