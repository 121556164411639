import { css } from '@emotion/react';

const aviancaFonts = css`
  @font-face {
    font-display: fallback;
    font-family: Lato;
    src: url('/fonts/Lato-Regular.woff2');
  }
`;

export default aviancaFonts;
