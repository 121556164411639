import { Fragment } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import useTranslation from 'next-translate/useTranslation';
import { FooterItemFragment } from '@codegen/cmsUtils';
import { legacySpacings } from '@ui-v2/theme/layout';
import {
  centerVertically,
  createTypography,
  hideDuringPrint,
} from '../../styles/base';
import Button, { ButtonType } from '../Button/Button';
import { LayoutGrid } from '../Grid/Grid';
import Image from '../Image';
import Link from '../Link/Link';

const StyledFooterContainter = styled.div(({ theme: { colours } }) => [
  centerVertically,
  hideDuringPrint,
  css`
    width: 100%;
    padding: ${legacySpacings.Medium}px 0;
    background: ${colours.surface.main};
    color: ${colours.text.default};
  `,
]);

const StyledLink = styled(Link)<{
  isSingleItem?: boolean;
}>(({ theme: { colours, typography } }) => [
  createTypography(typography.body02),
  css`
    display: flex;
    color: ${colours.text.default};
    text-decoration: underline;
  `,
]);

const StyledButton = styled(Button)<{
  isSingleItem?: boolean;
}>(({ theme: { colours, typography } }) => [
  createTypography(typography.body02),
  css`
    display: flex;
    color: ${colours.text.default};
    text-decoration: underline;
  `,
]);

const ImageWrapper = styled.span(
  ({ theme: { spacings } }) => css`
    display: flex;
    margin-left: ${spacings['8']}px;
  `,
);

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: nowrap;
`;

const StyledImage = styled(Image, {
  shouldForwardProp: (prop) =>
    !['isSingleItem', 'controlledHeight'].includes(prop),
})<{
  controlledHeight?: number;
}>(({ controlledHeight }) => [
  css`
    width: auto;
    height: ${controlledHeight ? `${controlledHeight}px` : 'auto'};
  `,
]);

const StyledLayoutGrid = styled(LayoutGrid)`
  width: 100%;
`;

export interface Props {
  footerItems?: FooterItemFragment[];
  showPrivacySettingsLink?: boolean;
}

const Footer = ({ footerItems, showPrivacySettingsLink }: Props) => {
  const { t } = useTranslation();
  const numberOfFooterItems = footerItems?.length
    ? footerItems.length + Number(showPrivacySettingsLink)
    : Number(showPrivacySettingsLink);

  const footerItemsColSize =
    // Use 25% if there are 4 or more footer items otherwise splits it into even columns
    numberOfFooterItems >= 4 ? 25 : 100 / numberOfFooterItems;

  return (
    <StyledFooterContainter>
      <StyledLayoutGrid
        display="grid"
        gap={[
          legacySpacings.ExtraSmall,
          legacySpacings.ExtraSmall,
          legacySpacings.ExtraSmall,
          0,
        ]}
        gridTemplateColumns={[
          'auto',
          'auto',
          'auto',
          footerItems
            ? `repeat(auto-fill, minmax(${footerItemsColSize}%, 1fr))`
            : 'auto',
        ]}
        justifyItems="center"
      >
        {footerItems?.map((item) => (
          <Fragment key={item.id}>
            {item.link && (
              <StyledLink
                href={item.link.href ? item.link.href : ''}
                target={item.link.target ?? undefined}
              >
                {item.image ? (
                  <>
                    {item.label?.value || item.link.label.value}
                    <ImageWrapper>
                      <Image
                        alt={item.label?.value || item.link.label.value}
                        height={item.image.asset.height}
                        objectFit="contain"
                        src={item.image.asset.url}
                        width={item.image.asset.width}
                      />
                    </ImageWrapper>
                  </>
                ) : (
                  item.link.label.value
                )}
              </StyledLink>
            )}
            {item.image && !item.link && (
              <ImageContainer>
                <span>{item.label?.value}</span>
                <ImageWrapper>
                  <StyledImage
                    alt={item.label?.value}
                    controlledHeight={item.image.height}
                    height={item.image.asset.height}
                    objectFit="contain"
                    src={item.image.asset.url}
                    width={item.image.asset.width}
                  />
                </ImageWrapper>
              </ImageContainer>
            )}
          </Fragment>
        ))}
        {showPrivacySettingsLink && (
          <StyledButton
            buttonType={ButtonType.RAW}
            isSingleItem={!footerItems || footerItems.length === 0}
            onClick={() => window.UC_UI?.showSecondLayer()}
          >
            {t('Privacy Settings')}
          </StyledButton>
        )}
      </StyledLayoutGrid>
    </StyledFooterContainter>
  );
};

export default Footer;
