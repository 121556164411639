import { createContext, ReactNode, useContext, useCallback } from 'react';
import { BaseAnalyticsProvider } from '@ui-v2/utils/contexts/BaseAnalyticsContext';
import {
  modalShown,
  popupShown,
  tooltipShown,
  pciproxyLoaded,
  passengerInfoStarted,
  passengerInfoEntered,
  billingAddressEntered,
  billingAddressStarted,
  contactInfoStarted,
  contactInfoEntered,
  partialPassengerInfoStarted,
  partialPassengerInfoEntered,
  paymentFormStarted,
  productViewedError,
  orderPriceMismatch,
} from '@web/utils/analytics/Avo';
import useAnalyticsQueue from './hooks/useAnalyticsQueue';
import useCreateGlobalAnalyticsClickHandlers from './hooks/useCreateGlobalAnalyticsClickHandlers';
import {
  sendProductViewedEvent,
  sendProductClickedEvent,
  sendPriceMismatchEvent,
  sendAddServiceEvent,
  sendServiceMismatchEvent,
  sendUpdatePassengersEvent,
  sendOrderCompletedEvent,
  sendPaymentInfoEnteredEvent,
  sendSearchResultAnalyticsEvent,
  sendBundleSelectedEvent,
  sendCreateOrderErrorEvent,
} from './utils/analyticsContextUtils';
import '@web/utils/analytics/AvoRudderstackBridge';

export type Analytics = {
  processAnalyticsQueue: () => void;
  sendAddServiceEvent: typeof sendAddServiceEvent;
  sendBillingAddressEnteredEvent: typeof billingAddressEntered;
  sendBillingAddressStartedEvent: typeof billingAddressStarted;
  sendBundleSelectedEvent: typeof sendBundleSelectedEvent;
  sendContactInfoEnteredEvent: typeof contactInfoEntered;
  sendContactInfoStartedEvent: typeof contactInfoStarted;
  sendCreateOrderErrorEvent: typeof sendCreateOrderErrorEvent;
  sendOrderCompletedEvent: typeof sendOrderCompletedEvent;
  sendOrderPriceMismatch: typeof orderPriceMismatch;
  sendPCIProxyLoadedEvent: typeof pciproxyLoaded;
  sendPartialPassengerInfoEnteredEvent: typeof partialPassengerInfoEntered;
  sendPartialPassengerInfoStartedEvent: typeof partialPassengerInfoStarted;
  sendPassengerInfoEnteredEvent: typeof passengerInfoEntered;
  sendPassengerInfoStartedEvent: typeof passengerInfoStarted;
  sendPaymentFormStartedEvent: typeof paymentFormStarted;
  sendPaymentInfoEnteredEvent: typeof sendPaymentInfoEnteredEvent;
  sendPriceMismatchEvent: typeof sendPriceMismatchEvent;
  sendProductClickedEvent: typeof sendProductClickedEvent;
  sendProductViewedError: typeof productViewedError;
  sendProductViewedEvent: typeof sendProductViewedEvent;
  sendSearchResultAnalyticsEvent: typeof sendSearchResultAnalyticsEvent;
  sendServiceMismatchEvent: typeof sendServiceMismatchEvent;
  sendUpdatePassengersEvent: typeof sendUpdatePassengersEvent;
};

const context = createContext<Analytics>({
  sendSearchResultAnalyticsEvent: () => {},
  sendProductViewedEvent: () => {},
  sendProductClickedEvent: () => {},
  sendPriceMismatchEvent: () => {},
  sendAddServiceEvent: () => {},
  sendServiceMismatchEvent: () => {},
  sendUpdatePassengersEvent: () => {},
  sendOrderCompletedEvent: () => {},
  sendPaymentInfoEnteredEvent: () => {},
  sendBundleSelectedEvent: () => {},
  processAnalyticsQueue: () => {},
  sendPCIProxyLoadedEvent: () => {},
  sendBillingAddressEnteredEvent: () => {},
  sendBillingAddressStartedEvent: () => {},
  sendContactInfoEnteredEvent: () => {},
  sendContactInfoStartedEvent: () => {},
  sendPartialPassengerInfoEnteredEvent: () => {},
  sendPartialPassengerInfoStartedEvent: () => {},
  sendPassengerInfoEnteredEvent: () => {},
  sendPaymentFormStartedEvent: () => {},
  sendPassengerInfoStartedEvent: () => {},
  sendProductViewedError: () => {},
  sendOrderPriceMismatch: () => {},
  sendCreateOrderErrorEvent: () => {},
});

export default context;

export const AnalyticsProvider = ({ children }: { children: ReactNode }) => {
  const { processAnalyticsQueue, pushToAnalyticsQueue } = useAnalyticsQueue();

  useCreateGlobalAnalyticsClickHandlers();

  const sendProductViewedEventCallback = useCallback(
    (...params: Parameters<typeof sendProductViewedEvent>) =>
      pushToAnalyticsQueue(() => sendProductViewedEvent(...params)),
    [pushToAnalyticsQueue],
  );

  const sendProductClickedEventCallback = useCallback(
    (...params: Parameters<typeof sendProductClickedEvent>) =>
      pushToAnalyticsQueue(() => sendProductClickedEvent(...params)),
    [pushToAnalyticsQueue],
  );

  const sendPriceMismatchEventCallback = useCallback(
    (...params: Parameters<typeof sendPriceMismatchEvent>) =>
      pushToAnalyticsQueue(() => sendPriceMismatchEvent(...params)),
    [pushToAnalyticsQueue],
  );

  const sendAddServiceEventCallback = useCallback(
    (...params: Parameters<typeof sendAddServiceEvent>) =>
      pushToAnalyticsQueue(() => sendAddServiceEvent(...params)),
    [pushToAnalyticsQueue],
  );

  const sendServiceMismatchEventCallback = useCallback(
    (...params: Parameters<typeof sendServiceMismatchEvent>) =>
      pushToAnalyticsQueue(() => sendServiceMismatchEvent(...params)),
    [pushToAnalyticsQueue],
  );

  const sendUpdatePassengersEventCallback = useCallback(
    (...params: Parameters<typeof sendUpdatePassengersEvent>) =>
      pushToAnalyticsQueue(() => sendUpdatePassengersEvent(...params)),
    [pushToAnalyticsQueue],
  );

  const sendOrderCompletedEventCallback = useCallback(
    (...params: Parameters<typeof sendOrderCompletedEvent>) =>
      pushToAnalyticsQueue(() => sendOrderCompletedEvent(...params)),
    [pushToAnalyticsQueue],
  );

  const sendPaymentInfoEnteredEventCallback = useCallback(
    (...params: Parameters<typeof sendPaymentInfoEnteredEvent>) =>
      pushToAnalyticsQueue(() => sendPaymentInfoEnteredEvent(...params)),
    [pushToAnalyticsQueue],
  );

  const sendBundleSelectedEventCallback = useCallback(
    (...params: Parameters<typeof sendBundleSelectedEvent>) =>
      pushToAnalyticsQueue(() => sendBundleSelectedEvent(...params)),
    [pushToAnalyticsQueue],
  );

  const sendSearchResultAnalyticsEventCallback = (
    ...params: Parameters<typeof sendSearchResultAnalyticsEvent>
  ) => pushToAnalyticsQueue(() => sendSearchResultAnalyticsEvent(...params));

  const sendModalShownEventCallback = useCallback(
    (...params: Parameters<typeof modalShown>) =>
      pushToAnalyticsQueue(() => modalShown(...params)),
    [pushToAnalyticsQueue],
  );

  const sendPopupShownEventCallback = useCallback(
    (...params: Parameters<typeof popupShown>) =>
      pushToAnalyticsQueue(() => popupShown(...params)),
    [pushToAnalyticsQueue],
  );

  const sendTooltipShownEventCallback = useCallback(
    (...params: Parameters<typeof tooltipShown>) =>
      pushToAnalyticsQueue(() => tooltipShown(...params)),
    [pushToAnalyticsQueue],
  );

  const sendPCIProxyLoadTimeEventCallback = useCallback(
    (...params: Parameters<typeof pciproxyLoaded>) =>
      pushToAnalyticsQueue(() => pciproxyLoaded(...params)),
    [pushToAnalyticsQueue],
  );

  const sendPassengerInfoStartedEventCallback = useCallback(
    (...params: Parameters<typeof passengerInfoStarted>) =>
      pushToAnalyticsQueue(() => passengerInfoStarted(...params)),
    [pushToAnalyticsQueue],
  );

  const sendPassengerInfoEnteredEventCallback = useCallback(
    (...params: Parameters<typeof passengerInfoEntered>) =>
      pushToAnalyticsQueue(() => passengerInfoEntered(...params)),
    [pushToAnalyticsQueue],
  );

  const sendBillingAddressEnteredEventCallback = useCallback(
    (...params: Parameters<typeof billingAddressEntered>) =>
      pushToAnalyticsQueue(() => billingAddressEntered(...params)),
    [pushToAnalyticsQueue],
  );

  const sendBillingAddressStartedEventCallback = useCallback(
    (...params: Parameters<typeof billingAddressStarted>) =>
      pushToAnalyticsQueue(() => billingAddressStarted(...params)),
    [pushToAnalyticsQueue],
  );

  const sendContactInfoStartedEventCallback = useCallback(
    (...params: Parameters<typeof contactInfoStarted>) =>
      pushToAnalyticsQueue(() => contactInfoStarted(...params)),
    [pushToAnalyticsQueue],
  );

  const sendContactInfoEnteredEventCallback = useCallback(
    (...params: Parameters<typeof contactInfoEntered>) =>
      pushToAnalyticsQueue(() => contactInfoEntered(...params)),
    [pushToAnalyticsQueue],
  );

  const sendPartialPassengerInfoStartedEventCallback = useCallback(
    (...params: Parameters<typeof partialPassengerInfoStarted>) =>
      pushToAnalyticsQueue(() => partialPassengerInfoStarted(...params)),
    [pushToAnalyticsQueue],
  );

  const sendPartialPassengerInfoEnteredEventCallback = useCallback(
    (...params: Parameters<typeof partialPassengerInfoEntered>) =>
      pushToAnalyticsQueue(() => partialPassengerInfoEntered(...params)),
    [pushToAnalyticsQueue],
  );

  const sendPaymentFormStartedEventCallback = useCallback(
    (...params: Parameters<typeof paymentFormStarted>) =>
      pushToAnalyticsQueue(() => paymentFormStarted(...params)),
    [pushToAnalyticsQueue],
  );

  const sendProductViewedErrorCallback = useCallback(
    (...params: Parameters<typeof productViewedError>) =>
      pushToAnalyticsQueue(() => productViewedError(...params)),
    [pushToAnalyticsQueue],
  );

  const sendOrderPriceMismatch = useCallback(
    (...params: Parameters<typeof orderPriceMismatch>) =>
      pushToAnalyticsQueue(() => orderPriceMismatch(...params)),
    [pushToAnalyticsQueue],
  );

  const sendCreateOrderErrorEventCallback = useCallback(
    (...params: Parameters<typeof sendCreateOrderErrorEvent>) =>
      pushToAnalyticsQueue(() => sendCreateOrderErrorEvent(...params)),
    [pushToAnalyticsQueue],
  );

  return (
    <context.Provider
      value={{
        sendProductViewedEvent: sendProductViewedEventCallback,
        sendProductClickedEvent: sendProductClickedEventCallback,
        sendPriceMismatchEvent: sendPriceMismatchEventCallback,
        sendAddServiceEvent: sendAddServiceEventCallback,
        sendServiceMismatchEvent: sendServiceMismatchEventCallback,
        sendUpdatePassengersEvent: sendUpdatePassengersEventCallback,
        sendOrderCompletedEvent: sendOrderCompletedEventCallback,
        sendPaymentInfoEnteredEvent: sendPaymentInfoEnteredEventCallback,
        sendBundleSelectedEvent: sendBundleSelectedEventCallback,
        sendSearchResultAnalyticsEvent: sendSearchResultAnalyticsEventCallback,
        sendPCIProxyLoadedEvent: sendPCIProxyLoadTimeEventCallback,
        sendPassengerInfoStartedEvent: sendPassengerInfoStartedEventCallback,
        sendPassengerInfoEnteredEvent: sendPassengerInfoEnteredEventCallback,
        sendBillingAddressEnteredEvent: sendBillingAddressEnteredEventCallback,
        sendBillingAddressStartedEvent: sendBillingAddressStartedEventCallback,
        sendContactInfoStartedEvent: sendContactInfoStartedEventCallback,
        sendContactInfoEnteredEvent: sendContactInfoEnteredEventCallback,
        sendPartialPassengerInfoStartedEvent:
          sendPartialPassengerInfoStartedEventCallback,
        sendPartialPassengerInfoEnteredEvent:
          sendPartialPassengerInfoEnteredEventCallback,
        sendPaymentFormStartedEvent: sendPaymentFormStartedEventCallback,
        sendProductViewedError: sendProductViewedErrorCallback,
        processAnalyticsQueue,
        sendOrderPriceMismatch,
        sendCreateOrderErrorEvent: sendCreateOrderErrorEventCallback,
      }}
    >
      <BaseAnalyticsProvider
        sendModalShownEvent={sendModalShownEventCallback}
        sendPopupShownEvent={sendPopupShownEventCallback}
        sendTooltipShownEvent={sendTooltipShownEventCallback}
      >
        {children}
      </BaseAnalyticsProvider>
    </context.Provider>
  );
};

export const useAnalytics = () => useContext(context);
