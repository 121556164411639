import { ReactNode } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ColorFragment, ImageFragment } from '@codegen/cmsUtils';
import { legacyZIndices } from '@ui-v2/theme/zIndices';
import { centerVertically, hideDuringPrint } from '../../styles/base';
import { GridWrap, GridColumn, GridRow } from '../Grid/Grid';

export interface Props {
  backgroundColor?: Maybe<ColorFragment>;
  children: ReactNode;
  className?: string;
  image: Maybe<ImageFragment>;
}

const StyledHeaderBar = styled.div<{
  backgroundColor?: Maybe<ColorFragment>;
  image?: Maybe<ImageFragment>;
}>(({ image, theme: { colours } }) => [
  centerVertically,
  hideDuringPrint,
  css`
    position: relative;
    z-index: ${legacyZIndices.z1};
    padding: 12px 0;
    background: ${colours.components.header.surface};
    color: ${colours.components.header.text};
  `,
  image &&
    css`
      background-image: url(${image.url});
      background-position: right;
      background-repeat: no-repeat;
      background-size: cover;
    `,
]);

const StyledContent = styled(GridColumn)(
  () => css`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  `,
);

const HeaderBar = ({ children, className, image }: Props) => {
  return (
    <StyledHeaderBar className={className} image={image}>
      <GridWrap>
        <GridRow>
          <StyledContent>{children}</StyledContent>
        </GridRow>
      </GridWrap>
    </StyledHeaderBar>
  );
};

export default HeaderBar;
