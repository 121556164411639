import Box from '../Box/Box';
import Button from '../Button/Button';
import { ModalProps } from './Modal';

interface Props {
  onOpenChange: ModalProps['onOpenChange'];
  text: string;
}

const OneButtonModalFooter = ({ onOpenChange, text }: Props) => {
  return (
    <Box display="flex" justifyContent="flex-end">
      <Button onClick={() => onOpenChange?.(false)} size="small">
        {text}
      </Button>
    </Box>
  );
};

export default OneButtonModalFooter;
