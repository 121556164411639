import styled from '@emotion/styled';
import { ImageWithConfigFragment } from '@codegen/cmsUtils';
import { IconColourProp, ThemeResponsiveProp } from '@ui-v2/types/props';
import { buildResponsiveValues } from '@ui-v2/utils/buildResponsiveValues';
import { retrieveThemeValues } from '@ui-v2/utils/retrieveThemeValues';
import { createCSSFilterFromHex } from '@ui-v2/utils/styleUtils';
import Image from '../Image/Image';

const DEFAULT_ICON_SIZE = 12;

export type IconProps = {
  colour?: IconColourProp;
  height?: ThemeResponsiveProp<number>;
  icon: ImageWithConfigFragment | null;
  size?: ThemeResponsiveProp<number>;
  width?: ThemeResponsiveProp<number>;
};

const StyledIcon = styled(Image, {
  shouldForwardProp: (prop) =>
    prop !== 'boundaryWidth' && prop !== 'boundaryHeight',
})<
  Omit<IconProps, 'height' | 'width' | 'color' | 'icon'> & {
    boundaryHeight: ThemeResponsiveProp<number>;
    boundaryWidth: ThemeResponsiveProp<number>;
  }
>(({ boundaryHeight, boundaryWidth, colour = 'icons.default', theme }) => [
  createCSSFilterFromHex(
    retrieveThemeValues(theme.colours, colour) as unknown as string,
  ),
  buildResponsiveValues({
    width: boundaryWidth,
    height: boundaryHeight,
  }),
]);

const Icon = ({ height, icon, size, width, ...props }: IconProps) => {
  const definedWidth = width ?? size ?? DEFAULT_ICON_SIZE;
  const definedHeight = height ?? size ?? DEFAULT_ICON_SIZE;

  const imageSrcWidth = Array.isArray(definedWidth)
    ? Math.max(...definedWidth)
    : definedWidth;

  const imageSrcHeight = Array.isArray(definedHeight)
    ? Math.max(...definedHeight)
    : definedHeight;

  if (!icon) {
    console.warn('Icon not found in Icon.tsx');

    return null;
  }

  return (
    <StyledIcon
      alt={icon.asset.alt}
      boundaryHeight={definedHeight}
      boundaryWidth={definedWidth}
      height={imageSrcHeight}
      src={icon.asset.url}
      width={imageSrcWidth}
      {...props}
    />
  );
};

export default Icon;
