import { Theme } from '@emotion/react';
import { ButtonSize, ButtonVariant } from '@ui-v2/types/buttons';
import {
  BackgroundColourProp,
  IconColourProp,
  ThemeResponsiveProp,
} from '@ui-v2/types/props';
import { createTypographyObjectNotation } from '@ui-v2/utils/styleUtils';

type ButtonSizeStyleConfig = {
  fontFamily?: string;
  fontSize?: ThemeResponsiveProp<number>;
  fontWeight?: number;
  height?: ThemeResponsiveProp<number>;
  letterSpacing?: number;
  lineHeight?: string;
  paddingBottom?: ThemeResponsiveProp<number>;
  paddingLeft?: ThemeResponsiveProp<number>;
  paddingRight?: ThemeResponsiveProp<number>;
  paddingTop?: ThemeResponsiveProp<number>;
  textTransform?: string;
  width?: ThemeResponsiveProp<number>;
};

export const buildButtonSize = (
  size: ButtonSize,
  theme: Theme,
): ButtonSizeStyleConfig => {
  switch (size) {
    case 'small':
      return {
        ...createTypographyObjectNotation(theme.typography.heading05),
        paddingTop: [7],
        paddingBottom: [7],
        paddingLeft: [32],
        paddingRight: [32],
      };
    case 'regular':
      return {
        ...createTypographyObjectNotation(theme.typography.heading04),
        paddingTop: [9],
        paddingBottom: [9],
        paddingLeft: [32],
        paddingRight: [32],
      };
    case 'iconExtraSmall':
      return {
        width: 18,
        height: 18,
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
      };
    case 'iconSmall':
      return {
        ...createTypographyObjectNotation(theme.typography.heading05),
        width: 40,
        height: 40,
      };
    case 'iconRegular':
      return {
        ...createTypographyObjectNotation(theme.typography.heading06),
        width: 48,
        height: 48,
      };
  }
};

type ButtonVariantStyleConfig = {
  '&:active': {
    background?: string;
    border?: string;
    color: string;
  };
  '&:focus': {
    background?: string;
    border?: string;
    color: string;
  };
  '&:hover': {
    background?: string;
    border?: string;
    color: string;
  };
  background?: string;
  border?: string;

  borderRadius: string | number;

  color: string;
};

export const buildButtonVariant = (
  variant: ButtonVariant,
  theme: Theme,
): ButtonVariantStyleConfig => {
  switch (variant) {
    case 'primary': {
      return {
        background: theme.colours.interactive.primary.default,
        borderRadius: Number(theme.shape.buttonPrimaryBorderRadius),
        color: theme.colours.text.on.interactive.primary.default,

        '&:hover': {
          background: theme.colours.interactive.primary.hover,
          color: theme.colours.text.on.interactive.primary.default,
        },

        '&:active': {
          background: theme.colours.interactive.primary.pressed,
          color: theme.colours.text.on.interactive.primary.default,
        },
        '&:focus': {
          background: theme.colours.interactive.primary.pressed,
          color: theme.colours.text.on.interactive.primary.default,
        },
      };
    }

    case 'secondary': {
      return {
        background: theme.colours.interactive.secondary.default,
        borderRadius: theme.shape.buttonSecondaryBorderradius,
        color: theme.colours.text.on.interactive.secondary.default,
        border: `1px solid ${theme.colours.border.interactive}`,

        '&:hover': {
          background: theme.colours.interactive.secondary.hover,
          color: theme.colours.text.on.interactive.secondary.default,
        },

        '&:active': {
          background: theme.colours.interactive.secondary.pressed,
          color: theme.colours.text.on.interactive.secondary.default,
        },
        '&:focus': {
          background: theme.colours.interactive.secondary.pressed,
          color: theme.colours.text.on.interactive.secondary.default,
        },
      };
    }

    case 'tertiary': {
      return {
        borderRadius: theme.shape.buttonTertiaryBorderRadius,
        color: theme.colours.interactive.primary.default,

        '&:hover': {
          background: theme.colours.interactive.tertiary.hover,
          color: theme.colours.interactive.primary.hover,
        },

        '&:active': {
          color: theme.colours.interactive.primary.pressed,
        },
        '&:focus': {
          color: theme.colours.interactive.primary.pressed,
        },
      };
    }
  }
};

type IconStyleConfig = {
  colour: IconColourProp;
  height: ThemeResponsiveProp<number>;
  width: ThemeResponsiveProp<number>;
};

const iconSizeMapper: Record<ButtonSize, number> = {
  iconExtraSmall: 18,
  iconRegular: 24,
  iconSmall: 24,
  small: 24,
  regular: 24,
};

export const buildButtonIconConfig = (
  size: ButtonSize,
  variant: ButtonVariant,
): IconStyleConfig => {
  const config = {
    width: iconSizeMapper[size],
    height: iconSizeMapper[size],
  };

  switch (variant) {
    case 'primary': {
      return {
        ...config,
        colour: 'icons.on.primary.default',
      };
    }

    case 'secondary': {
      return {
        ...config,
        colour: 'icons.on.secondary.default',
      };
    }

    case 'tertiary': {
      return {
        ...config,
        colour: 'interactive.primary.pressed',
      };
    }
  }
};

type LoadingStyleConfig = {
  bg?: BackgroundColourProp;
  colour: IconColourProp;
};

export const buildLoaderConfig = (
  variant: ButtonVariant,
): LoadingStyleConfig => {
  switch (variant) {
    case 'primary': {
      return {
        colour: 'icons.on.primary.default',
        bg: 'interactive.primary.default',
      };
    }

    case 'secondary': {
      return {
        colour: 'icons.on.secondary.default',
        bg: 'interactive.secondary.default',
      };
    }

    case 'tertiary': {
      return {
        colour: 'interactive.primary.default',
        bg: 'surface.disabled',
      };
    }
  }
};
